<template>
  <v-container fluid grey lighten-3 pa-10>
    <v-row>
      <v-col md="6" cols="12">
        <h3 class="title mt-5 text-right">
          KERRY :: PICKUP LIST
        </h3>
      </v-col>
      <v-col md="6" cols="12">
        <v-file-input
          label="File input นามสกุล xlsx เท่านั้น"
          accept=".xlsx"
          prepend-icon="mdi-file-excel"
          type="file"
          @change="handleChange"
        ></v-file-input>
      </v-col>
    </v-row>

    <v-card>
      <v-data-table
        v-model="selected"
        :headers="headers"
        :items="dataTrack"
        item-key="track"
        :loading="dataTableLoading"
        hide-default-footer
        disable-pagination
        show-select
        disable-filtering
        disable-sort
        mobile-breakpoint="200"
      >
        <!-- table top section -->
        <template v-slot:top>
          <v-toolbar flat color="grey lighten-4" light height="55">
            <v-badge size="18" color="yellow accent-4">
              <template v-slot:badge>
                <span class="black--text">{{ selected.length }}</span>
              </template>
              เลือก
            </v-badge>
            <v-divider class="mx-7" inset vertical=""></v-divider>
            {{ totalTracking }} {{ responseUpdate }}
            <v-spacer></v-spacer>
            <v-btn
              color="yellow darken-1"
              @click="updateTracking"
              light
              class="mb-1 mr-1"
              small
            >
              <v-icon class="mr-1">mdi-database</v-icon>
              Update Tracking
            </v-btn>
          </v-toolbar>
        </template>

        <template v-slot:item="{ item, select, isSelected, index }">
          <tr :key="index">
            <td>
              <v-simple-checkbox
                @input="select"
                :value="isSelected"
                :ripple="false"
              ></v-simple-checkbox>
            </td>

            <td>
              {{ item.track }} <v-chip
                x-small
                class="pa-1 ml-1"
                label
                color="yellow"
                v-if="item.data.is_cod == paymentmethod.COD"
                >COD</v-chip
              >
            </td>
            <td>
              {{ item.pickup_date }}
            </td>
            <td class="blue lighten-5 caption">
              <span v-if="item.updated" class="green--text"
                ><v-icon small color="green"
                  >mdi-checkbox-marked-circle</v-icon
                ></span
              >
              <span class="red--text" v-else
                ><v-icon small color="red">mdi-close-circle</v-icon></span
              >
              {{ item.message }}
              <span v-if="item.data.tracking_date">{{
                item.data.tracking_date | moment("YYYY-MM-DD HH:mm")
              }}</span>
            </td>
            <td class="blue lighten-5">
              {{ item.data.idorderformat }} | {{ item.data.name }}
            </td>
            <td class="blue lighten-5">
              {{ item.data.tel }}
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import api, { CODBANK,PAYMENTMETHOD } from "@/services/api";
import Exceljs from "exceljs";
import _ from "lodash";

export default {
  name: "UploadTrackingKerryAcc",
  data() {
    return {
      paymentmethod: PAYMENTMETHOD,
      selected: [],
      dataTableLoading: false,
      file: "",
      data: [],
      dataTrack: [],
      headers: [
        { text: "Tracking no", value: "track", class: "lime lighten-1" },
        {
          text: "Pickup date",
          value: "pickup_date",
          class: "yellow lighten-1",
        },
        {
          text: "ข้อมูลระบบ / pickup-date",
          value: "updated",
          class: "blue lighten-4",
        },
        { text: "Name in DB", value: "naem2", class: "blue lighten-4" },
        { text: "Tel in DB", value: "tel2", class: "blue lighten-4" },
      ],
    };
  },
  computed: {
    totalTracking() {
      let total = `Record(s): ${this.dataTrack.length}`;
      let orderfounds = _.filter(this.dataTrack, { inOrder: true });
      let totalfound = ` found(s): ${orderfounds.length}`;
      return `${total} ${totalfound}`;
    },
    responseUpdate() {
      let rSucceed = _.filter(this.dataTrack, { resultupdated: true });
      let rFailed = _.filter(this.dataTrack, { resultupdated: false });
      return ` succeed: ${rSucceed.length} failed: ${rFailed.length}`;
    },
  },
  methods: {
    handleChange(e) {
      // console.log(e);
      // this.file = e.target.files[0];
      this.selected = [];
      if (e) {
        this.file = e;
        this.handleImport();
      } else {
        this.dataTrack.splice(0, this.dataTrack.length);
        this.data.splice(0, this.data.length);
      }
    },
    handleImport() {
      this.dataTableLoading = true;
      const wb = new Exceljs.Workbook();
      const reader = new FileReader();

      reader.readAsArrayBuffer(this.file);
      reader.onload = () => {
        const buffer = reader.result;
        wb.xlsx.load(buffer).then((workbook) => {
          //console.log(workbook, 'workbook instance')
          workbook.eachSheet((sheet, id) => {
            sheet.eachRow((row, rowIndex) => {
              if (rowIndex > 1) {
                // let pickdate = _.trim(row.values[5]);
                // let picktime = _.trim(row.values[6]) || '00:00:00';
                // let ap = pickdate.split('/')
                // let newpickdate = `${ap[2]}-${ap[1]}-${ap[0]} ${picktime}`
                let pickdate = _.trim(row.values[8])
                let newpickdate = this.$moment(pickdate,"YYYY-MM-DD HH:mm A").format("YYYY-MM-DD HH:mm")
                // console.log('pickup date:', newpickdate)
                let dataRow = {
                  id: rowIndex,
                  pickup_date: newpickdate,
                  track: _.trim(row.values[3]),
                  shipping_cost: 0,
                  inOrder: false, // ไว้เช็คค่าในออเดอร์,
                  updated: false,
                  resultupdated: null,
                  message: "ไม่มีออเดอร์นี้",
                  data: {
                    name: "",
                    tel: "",
                  },
                };
                this.data.push({ ...dataRow });
              }
            });
          });
          // console.log(this.data)
          this.dataTableLoading = false;
          this.checkTrackToOrder();
        });
      };
    },
    async checkTrackToOrder() {
      // this.data.pop(); // ตัดแถวสุดท้ายออก เพราะเป็นข้อมูล ยอดรวม ใน excel
      if (this.data.length > 0) {
        this.dataTableLoading = true;
        let formData = new FormData();
        formData.append("items_selected", JSON.stringify(this.data));

        let result = await api.getOrderByTracks(formData);
        if (result.data.result == "ok") {
          _.delay(() => {
            this.dataTrack = JSON.parse(result.data.message);
            this.dataTableLoading = false;
          }, 1000);
        }
      }
    },
    async updateTracking() {
      if (confirm("ต้องการอัพเดต Traking no ไปที่ใบสั่งซื้อ ใช่หรือไม่?")) {
        this.dataTableLoading = true;
        if (this.selected.length) {
          let formData = new FormData();
          formData.append("items_selected", JSON.stringify(this.selected));
          formData.append("ref_bank_id_cod_kerry", CODBANK.KERRY); //ส่งไปก่อน เช็คเงื่อนไขใน server

          let result = await api.updatePickupListKerry(formData);
          if (result.data.result == "ok") {
            let data = JSON.parse(result.data.message);
            let initdata = this.dataTrack;

            _.forEach(data, (r, i) => {
              let trackindex = _.findIndex(initdata, { track: r.track });
              if (trackindex > -1) {
                initdata[trackindex] = r;
              }
            });

            this.dataTrack = [];
            _.delay(() => {
              this.dataTrack = initdata;
              this.dataTableLoading = false;
            }, 2000);
          }
        }
      }
    },
  },
  beforeDestroy: function() {
    delete this.selected;
    delete this.data;
    delete this.dataTrack;
  },
};
</script>

<style></style>
